<template>
  <div class="generate-qr">
    <hcc-button
      variant="transparent"
      color="muted"
      @click="resizeQr(true)">
        <qrcode-scan-icon />
        {{ $t("shop.generate-qr") }}
    </hcc-button>

    <hcc-modal
      :title="$t('shop.view-qr')"
      class="qr"
      @download="downloadQr"
      :buttons="['cancel', 'download']"
      :width="400"
      name="view-qr-code"
      :confirmDisableButton="true"
      :adaptive="true"
    >
      <overlay-loader :loading="loading" />
      <div ref="containerQr"
        class="generate-qr__container">
          <div class="generate-qr__logo">
            <inline-svg
              :src="LoginSellia"
              width="90"
              height="90"
            ></inline-svg>
          </div>
          <qrcode-vue
            id="qr-code"
            :value="url"
            :size="size"
            level="H"
          />
      </div>
    </hcc-modal>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import InlineSvg from 'vue-inline-svg';
import HccButton from '@/components/shared/HccButton/index.vue';
import HccModal from '@/components/shared/HccModal/index.vue';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import LoginSellia from '@/assets/images/LOGO_SELLIA-03.svg';

export default {
  components: {
    QrcodeVue,
    HccButton,
    HccModal,
    InlineSvg,
    OverlayLoader,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    client: {
      type: String,
      default: 'qr-code',
    },
  },
  computed: {
    url() {
      return `${window.location.protocol}//${window.location.host}/store/${this.id}/${this.client}`;
    },
  },
  data() {
    return {
      size: 0,
      output: null,
      loading: false,
      LoginSellia,
    };
  },
  created() {
    window.addEventListener('resize', this.resizeQr);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeQr);
  },
  methods: {
    async downloadQr() {
      this.loading = true;
      this.transformQrCodeAnImagel();
    },
    async transformQrCodeAnImagel() {
      const a = document.createElement('a');
      const Qr = this.$refs.containerQr;
      const options = {
        type: 'dataURL',
      };
      this.output = await this.$html2canvas(Qr, options);
      a.href = this.output;
      a.download = 'QR_CODE.png';
      a.click();
      this.loading = false;
    },
    resizeQr(show = false) {
      const qrCode = document.getElementById('qr-code');
      if (qrCode || show === true) {
        this.$modal.show('view-qr-code');
        setTimeout(() => {
          this.size = document.getElementById('qr-code').clientWidth;
        }, 200);
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import "~styles/components/qr-code.scss";
</style>
