var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shop-detail loader-container"},[_c('overlay-loader',{attrs:{"loading":_vm.loading}}),(_vm.myStore)?[_c('div',{staticClass:"shop-detail__header flex flex-column"},[_c('div',{staticClass:"shop-detail__header-logo"},[_c('img',{staticClass:"shop-detail__header-image",attrs:{"src":_vm.profilePic,"alt":"store-img"}})]),_c('div',{staticClass:"\n          shop-detail__header-description\n          shop-description shop-description-sm\n        "},[_c('div',{staticClass:"header-sm"},[_c('div',{staticClass:"shop-detail__header-title"},[_c('div',{staticClass:"shop-detail__header-name"},[_vm._v(_vm._s(_vm.myStore.nombre))]),_c('div',{staticClass:"shop-detail__header-id text-align-xs"},[_vm._v(" ("+_vm._s(_vm.myStore["name-id-canonical"])+") ")])]),_c('div',{staticClass:"header-sm"},[_c('div',{staticClass:"shop-detail__header-category"},[_vm._v(" "+_vm._s(_vm.myStore.categoria ? _vm.myStore.categoria.value : "-")+" ")]),_c('div',{staticClass:"shop-detail__header-about text-align-xs"},[_vm._v(" "+_vm._s(_vm.myStore.descripcion)+" ")]),_c('div',{staticClass:"shop-detail__header-address text-align-xs"},[_vm._v(" 📌 "+_vm._s(_vm.myStore.direccion)+" ")])])]),_c('div',{staticClass:"shop-detail__header-actions header-sm"},[_c('generate-qr',{attrs:{"id":_vm.myStore.id}}),_c('router-link',{attrs:{"target":"_blank","to":{
              name: 'invite.sellia',
              params: { id: _vm.myStore.id, client: 'demo' },
            }}},[_c('hcc-button',{attrs:{"variant":"transparent"}},[_c('eye-icon'),_vm._v(" "+_vm._s(_vm.$t("shop.view-as-visitor"))+" ")],1)],1),_c('hcc-button',{on:{"click":_vm.addProduct}},[_vm._v(" "+_vm._s(_vm.$t("shop.add-product"))+" ")])],1)])]),(!_vm.showStatistics)?_c('div',{staticClass:"shop-detail__body"},_vm._l((_vm.products),function(product){return _c('item-product',{key:product.id,staticClass:"flex-item flex-item-md flex-item-sm",attrs:{"data":product},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"shop-detail__body-footer"},[_c('router-link',{attrs:{"to":{
                name: 'store.product-info',
                params: {
                  idStore: _vm.$route.params.id,
                  idProduct: product.id
                }
              }}},[_c('hcc-button',{attrs:{"variant":"outline","size":_vm.buttonSize}},[_vm._v(" "+_vm._s(_vm.$t("shop.edit"))+" ")])],1),_c('hcc-button',{attrs:{"variant":"outline","color":"accent","size":_vm.buttonSize},on:{"click":function($event){return _vm.duplicate(product)}}},[_vm._v(" "+_vm._s(_vm.$t("shop.duplicate")))]),_c('hcc-button',{attrs:{"variant":"outline","color":"muted","size":_vm.buttonSize},on:{"click":function($event){return _vm.deleteProduct(product)}}},[_vm._v(" "+_vm._s(_vm.$t("shop.delete")))])],1)]},proxy:true}],null,true)})}),1):_vm._e()]:_vm._e(),_c('hcc-confirmation')],2)}
var staticRenderFns = []

export { render, staticRenderFns }